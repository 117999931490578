<template>
  <div class="bg-white p-3">
    <div class="content-options text-center py-4">
      <h2 class="fw-800 text-color10 ls-25">
        CRIAR CAMPANHA
      </h2>
      <h6 class="fw-500 ls-5 mb-0">POR FAVOR, SELECIONE O OBJETIVO DA SUA CAMPANHA:</h6>
      <div class="mt-4 mt-md-5">
        <div class="d-flex flex-column flex-lg-row p-0 justify-content-center align-items-center text-start mb-4 gap-4">
          <div class="col-10 col-sm-5 col-lg-3">
            <div class="bg-color12 py-3 px-4">
              <input v-model="form.objective" type="radio" id="opt-programatic" value="PROGRAMATIC" class="btn-check" />
              <label
                for="opt-programatic"
                class="font-size-xRegular d-flex gap-3"
                :class="form.objective === 'PROGRAMATIC'?'active':''"
                @mouseover="form.objective = 'PROGRAMATIC'"
                @click="scrollToObjective"
              >
                <BaseIcon
                  size="medium"
                >
                  <ProgramaticIcon />
                </BaseIcon>
                <div class="">
                  <span class="fw-700 ls-5">ALCANCE</span><br/>
                  <p class="font-size-xxSmall lh-sm mb-0">FORMATO:<br/> <strong>DISPLAY PROGRAMÁTICO</strong></p>
                </div>
              </label>
            </div>
          </div>
          <div class="col-10 col-sm-5 col-lg-3">
            <div class="bg-color12 py-3 px-4">
              <input v-model="form.objective" type="radio" id="opt-native" value="NATIVE" class="btn-check" />
              <label
                for="opt-native"
                class="font-size-xRegular d-flex gap-3"
                :class="form.objective === 'NATIVE'?'active':''"
                @mouseover="form.objective = 'NATIVE'"
                @click="scrollToObjective"
              >
                <BaseIcon
                  size="medium"
                >
                  <NativeIcon />
                </BaseIcon>
                <div class="">
                  <span class="fw-700 ls-5">TRÁFEGO</span><br/>
                  <p class="font-size-xxSmall lh-sm mb-0">FORMATO:<br/> <strong>ANÚNCIOS NATIVOS</strong></p>
                </div>
              </label>
            </div>
          </div>
          <div class="col-10 col-sm-5 col-lg-3">
            <div class="bg-color12 py-3 px-4">
              <input v-model="form.objective" type="radio" id="opt-search" value="SEARCH" class="btn-check" />
              <label
                for="opt-search"
                class="font-size-xRegular d-flex gap-3"
                :class="form.objective === 'SEARCH'?'active':''"
                @mouseover="form.objective = 'SEARCH'"
                @click="scrollToObjective"
              >
                <BaseIcon
                  size="medium"
                >
                  <SearchIcon />
                </BaseIcon>
                <div class="">
                  <span class="fw-700 ls-5">CONVERSÃO</span><br/>
                  <p class="font-size-xxSmall lh-sm mb-0">FORMATO:<br/> <strong>BUSCA PAGA</strong></p>
                </div>
              </label>
            </div>
          </div>
        </div>
        <div class="font-size-xSmall text-uppercase text-color11 ls-5 fw-500">
          <p>Com o objetivo selecionado, você irá segmentar sua audiência,<br/> enviar seus criativos, definir sua verba e iniciar sua campanha.</p>
          <p>Temos acesso a mais de 156 milhões de brasileiros, divididos em 300 mil segmentações.</p>
        </div>
      </div>
    </div>
    <div class="content-objective text-justify text-color11 mt-4" v-if="form.objective">
      <div v-if="form.objective === 'PROGRAMATIC'">
        <div class="d-lg-flex justify-content-between align-items-start">
          <div class="col-lg-4">
            <p class="ls-5 mb-2">FORMATO SELECIONADO:</p>
            <h3 class="fw-700 ls-25 text-color2 lh-1">DISPLAY PROGRAMÁTICO</h3>
            <p class="font-size-xxSmall lh-sm fst-italic">Banners nos principais sites e aplicativos, de acordo com seu público, nos formatos de imagens, vídeos ou GIFs.</p>
            <div class="info">
              <h6 class="font-size-xSmall fw-500 mb-2 text-color8">Métrica: CPM (Custo por Mil)
                <img src="/images/pages/campaign/icon-info.png"
                    width="15"
                    class="ms-2"
                    @mouseover="showInfo = true"
                    @mouseleave="showInfo = false"
                  />
              </h6>
              <p class="font-size-xxxSmall fw-500 lh-sm fst-italic mb-0" v-if="showInfo || isMobile">É o custo de mil impressões de anúncios em uma página.<br  class="d-none d-md-block"/> O valor é calculado dividindo o custo total da campanha<br  class="d-none d-md-block"/> pela quantidade total de impressões recebidas.</p>
            </div>
          </div>
          <div class="col-lg-8 d-md-flex gap-4">
            <div class="d-flex align-items-center gap-3 text-color8 mb-3 mb-md-0">
              <img src="/images/pages/campaign/icon-obj-programatic.png" alt="" width="50" class="mb-1">
              <div>
                <p class="font-size-xSmall fw-500 mb-1"><span class="text-color2">Objetivo:</span> Reconhecimento de Marca.</p>
                <p class="font-size-xxSmall fw-500 lh-sm mb-0">Este formato é recomendado para fazer<br class="d-none d-md-block"/> com que os consumidores conheçam sua<br class="d-none d-md-block"/> marca ou produto, ganhando credibilidade.</p>
              </div>
            </div>
            <div class="d-flex align-items-center gap-3 text-color8">
              <div class="funnel top">
                <img src="/images/pages/campaign/funnel.png" alt="" width="50">
              </div>
              <div class="">
                <p class="font-size-xSmall fw-500 mb-1"><span class="text-color2">Vendas:</span> Topo de Funil.</p>
                <p class="font-size-xxSmall fw-500 lh-sm mb-0">Primeira etapa do processo de vendas,<br class="d-none d-md-block"/> na qual os consumidores estão buscando<br class="d-none d-md-block"/> informações sobre os produtos e serviços.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="main-image text-center">
          <img src="/images/pages/campaign/opt-programatic.png" alt="">
          <span class="mobile">
            <div class="magnifier"></div>
            <div class="example">
              <img src="/images/pages/campaign/Programatica_01.jpg" alt="">
              <p><strong>Formato:</strong> 300x250</p>
            </div>
          </span>
          <span class="desktop">
            <div class="magnifier"></div>
            <div class="example">
              <img src="/images/pages/campaign/Programatica_02.jpg" alt="">
              <p><strong>Formato:</strong> 300x250</p>
            </div>
          </span>
          <span class="tablet">
            <div class="magnifier"></div>
            <div class="example">
              <img src="/images/pages/campaign/Programatica_03.jpg" alt="">
              <p><strong>Formato:</strong> 300x250</p>
            </div>
          </span>
        </div>
      </div>
      <div v-else-if="form.objective === 'NATIVE'">
        <div class="d-lg-flex justify-content-between align-items-start">
          <div class="col-lg-4">
            <p class="ls-5 mb-2">FORMATO SELECIONADO:</p>
            <h3 class="fw-700 ls-25 text-color2 lh-1">ANÚNCIOS NATIVOS</h3>
            <p class="font-size-xxSmall lh-sm fst-italic">Anúncios em formato não-intrusivo, se parecendo com<br class="d-none d-xl-block"/> notícias dentro de sites, blogs e páginas de conteúdo.</p>
            <div class="info">
              <h6 class="font-size-xSmall fw-500 mb-2 text-color8">Métrica: CPC (Custo por Clique)
                <img src="/images/pages/campaign/icon-info.png"
                    width="15"
                    class="ms-2"
                    @mouseover="showInfo = true"
                    @mouseleave="showInfo = false"
                />
              </h6>
              <p class="font-size-xxxSmall fw-500 lh-sm fst-italic" v-if="showInfo || isMobile">É o valor médio cobrado por um clique em um anúncio.<br  class="d-none d-md-block"/> O valor é calculado dividindo o custo total da campanha<br  class="d-none d-md-block"/> pelo número total de cliques efetivamente recebidos.</p>
            </div>
          </div>
          <div class="col-lg-8 d-md-flex gap-4">
            <div class="d-flex align-items-center gap-3 text-color8 mb-3 mb-md-0">
              <img src="/images/pages/campaign/icon-obj-native.png" alt="" width="50">
              <div>
                <p class="font-size-xSmall fw-500 mb-1"><span class="text-color2">Objetivo:</span> Engajamento com a Marca.</p>
                <p class="font-size-xxSmall fw-500 lh-sm mb-0">Este formato é recomendado para fazer<br class="d-none d-md-block"> com que os consumidores engajarem com<br class="d-none d-md-block"> a sua marca e a considerem para uma compra.</p>
              </div>
            </div>
            <div class="d-flex align-items-center gap-3 text-color8">
              <div class="funnel middle">
                <img src="/images/pages/campaign/funnel.png" alt="" width="50">
              </div>
              <div>
                <p class="font-size-xSmall fw-500 mb-1"><span class="text-color2">Vendas:</span> Meio de Funil.</p>
                <p class="font-size-xxSmall fw-500 lh-sm mb-0">É a fase intermediária do processo de compra,<br class="d-none d-md-block"> na qual visitantes interagem com seu criativo<br class="d-none d-md-block"> e passam a conhecer sua marca/produto/serviço.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="main-image text-center">
          <img src="/images/pages/campaign/opt-native.png" alt="">
          <div class="mobile">
            <div class="magnifier"></div>
            <div class="example native">
              <img src="/images/pages/campaign/Native_01.jpg" alt="">
              <p><strong>Formato:</strong> 1200x627</p>
            </div>
          </div>
          <div class="desktop">
            <div class="magnifier"></div>
            <div class="example native">
              <img src="/images/pages/campaign/Native_02.jpg" alt="">
              <p><strong>Formato:</strong> 1200x627</p>
            </div>
          </div>
          <div class="tablet">
            <div class="magnifier"></div>
            <div class="example native">
              <img src="/images/pages/campaign/Native_03.jpg" alt="">
              <p><strong>Formato:</strong> 1200x627</p>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="form.objective === 'SEARCH'">
        <div class="d-lg-flex justify-content-between align-items-start">
          <div class="col-lg-4">
            <p class="ls-5 mb-2">FORMATO SELECIONADO:</p>
            <h3 class="fw-700 ls-25 text-color2 lh-1 w-md-50">BUSCA PAGA</h3>
            <p class="font-size-xxSmall lh-sm fst-italic">É a compra de resultados da busca de palavras-chave<br class="d-none d-xl-block"/> específicas em mecanismos de busca, como Google Search.</p>
            <div class="info">
              <h6 class="font-size-xSmall fw-500 mb-2 text-color8">Métrica: CTR (Click Through Rate)
                <img src="/images/pages/campaign/icon-info.png"
                    width="15"
                    class="ms-2"
                    @mouseover="showInfo = true"
                    @mouseleave="showInfo = false"
                />
              </h6>
              <p class="font-size-xxxSmall fw-500 lh-sm fst-italic" v-if="showInfo || isMobile">É a taxa de cliques de um anúncio, ou seja, a relação entre<br class="d-none d-md-block"/> o número de cliques e a quantidade de visualizações. Quanto<br class="d-none d-md-block"/> maior a taxa, melhor a eficiência e os resultados do anúncio.</p>
            </div>
          </div>
          <div class="col-lg-8 d-md-flex gap-4">
            <div class="d-flex align-items-center gap-3 text-color8 mb-3 mb-md-0">
              <img src="/images/pages/campaign/icon-obj-search.png" alt="" width="50" style="margin-top: 0.08rem;">
              <div>
                <p class="font-size-xSmall fw-500 mb-1"><span class="text-color2">Objetivo:</span> Geração de Leads/Vendas.</p>
                <p class="font-size-xxSmall fw-500 lh-sm mb-0">Este formato é recomendado para atingir<br class="d-none d-md-block"> quem já está em um estágio avançado de<br class="d-none d-md-block"> compra ou já conhece sua marca/empresa.</p>
              </div>
            </div>
            <div class="d-flex align-items-center gap-3 text-color8">
              <div class="funnel bottom">
                <img src="/images/pages/campaign/funnel.png" alt="" width="50">
              </div>
              <div>
                <p class="font-size-xSmall fw-500 mb-1"><span class="text-color2">Vendas:</span> Fundo de Funil.</p>
                <p class="font-size-xxSmall fw-500 lh-sm mb-0">É a última etapa do processo de vendas,<br class="d-none d-md-block"> na qual os consumidores estão muito próximos<br class="d-none d-md-block"> de tomar uma decisão definitiva de compra.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="main-image text-center">
          <img src="/images/pages/campaign/opt-search.png" alt="">
        </div>
      </div>
      <div class="d-flex justify-content-end buttons">
        <Button
          class="btn btn-color10 py-2 px-3 fw-700 ls-5 font-size-xRegular font-size-md-regular"
          :disabled="!form.objective"
          v-if="form.objective !== 'SEARCH'"
          @click="submit"
        >
          Avançar
        </Button>
        <Button
          class="btn btn-color8 py-1 py-md-2 px-2 px-md-3 fw-700 ls-5 font-size-xSmall font-size-md-regular"
          v-if="form.objective === 'SEARCH'"
          v-b-modal="'contact-modal'"
        >Em breve</Button>
        <ContactModal
          buttonText="Enviar"
          headerTitle="Avise-me"
          headerDescription="Deixe seu contato para avisarmos quando essa funcionalidade estiver disponível"
        />
      </div>
    </div>
  </div>
</template>

<script>

import Button from '@/components/common/Button/Button'
import BaseIcon from '@/components/common/Icon/BaseIcon'
import ProgramaticIcon from '@/components/common/Icon/ProgramaticIcon'
import NativeIcon from '@/components/common/Icon/NativeIcon'
import SearchIcon from '@/components/common/Icon/SearchIcon'
import ContactModal from '@/components/common/Modal/ContactModal.vue'

export default {
  name: 'CreateCampaign',
  components: {
    BaseIcon,
    Button,
    ContactModal,
    NativeIcon,
    ProgramaticIcon,
    SearchIcon
  },
  data () {
    return {
      showInfo: false,
      form: {
        objective: ''
      }
    }
  },
  computed: {
    isMobile () {
      return window.innerWidth <= 992
    }
  },
  methods: {
    /**
     * Submit
     */
    submit () {
      if (this.form.objective === 'PROGRAMATIC') this.$router.push({ name: 'campaigns.programatic.create' })
      else if (this.form.objective === 'NATIVE') this.$router.push({ name: 'campaigns.native.create' })
    },
    scrollToObjective () {
      if (window.innerWidth <= 992) {
        const element = document.querySelector('.content-objective')
        const yOffset = -10 // Adjust as needed
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset
        window.scrollTo({ top: y, behavior: 'smooth' })
      }
    }
  }
}
</script>

<style lang="scss" scoped>

h2 {
  font-size: 35px;

  @media (min-width: 376px) {
    font-size: 40px;
  }

  @include desktop {
    font-size: 48px;
  }
}

.content-options {
  background-color: #f4f4f4;
}

.content-objective {
  background-color: #e8e8e8;
  right: 0;
  padding: 1rem;

  @include tablet {
    padding: 2rem;
  }
}

label {
  p {
    color: #aaaaaa;
  }

  &:hover, &:focus, &.active{
    ::v-deep {
      .color {
        fill: $color10;
      }
    }

    p {
      color: #000;
    }

    span {
      color: $color10;
    }
  }
}

.info {
  position: relative;
  z-index: 2;
  height: 65px;

  @include desktop-lg {
    height: 35px;
  }
}

.buttons {
  position: relative;
  z-index: 1;
  top: 7px;

  @include tablet {
    top: 15px;
  }
}

@keyframes blink {
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
}

.funnel {
  position: relative;
  display: inline-block;
  width: 50px;

  &.top::before, &.middle::before, &.bottom::before {
    content: "";
    position: absolute;
    background-color: $color1;
    opacity: 0;
    animation: blink 1s infinite;
    z-index: 0;
  }

  &.top {
    @include tablet {
      margin-left: 0.78rem;
    }
  }

  &.bottom {
    @include tablet {
      margin-left: 1.20rem;
    }
  }

  &.top::before {
    top: 2px;
    left: 3px;
    width: 88%;
    height: 20%;
  }

  &.middle::before {
    top: 16px;
    left: 8px;
    width: 68%;
    height: 20%;
  }

  &.bottom::before {
    top: 29px;
    left: 10px;
    width: 55%;
    height: 32%;
  }

  img {
    display: block;
    position: relative;
    z-index: 0;
    max-width: none;
  }
}

.main-image {
  position: relative;
  z-index: 1;
  top: 20px;
  margin-bottom: 20px;

  .desktop, .tablet {
    .example {
      display: none;
    }
  }

  .mobile {
    .example {
      margin: 1.5rem 0;
    }
  }

  @include desktop {
    top: -75px;
    margin-bottom: -120px;

    img {
      position: relative;
      z-index: 1;
    }

    .example {
      position: absolute;
      background-color: #fff;
      padding: 12px 12px 3px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      z-index: 4;
      display: none;

      &.native {
        width: 460px;
      }

      p {
        font-size: 8px;
        margin-bottom: 0;
      }
    }

    .mobile, .desktop, .tablet {
      position: absolute;
      z-index: 3;

      &:hover {
        .example {
          display: block;
          text-align: right;
        }
      }

      .magnifier {
        content: '';
        background: url('/images/pages/campaign/magnifier-icon.png') no-repeat;
        background-size: contain;
        width: 50px;
        height: 50px;
        position: absolute;
        z-index: 1;
        cursor: pointer;
      }
    }

    .mobile {
      width: 250px;
      height: 250px;
      left: 50px;
      bottom: -26px;
      z-index: 4;

      .magnifier {
        right: 0;
        top: 70px;
      }

      .example {
        bottom: 0;

        &.native {
          top: -10px;
          left: -78px;
        }
      }
    }

    .desktop {
      width: 330px;
      height: 330px;
      left: calc(50% - 165px);
      bottom: -26px;

      .magnifier {
        right: -85px;
        top: 40px;
      }

      .example {
        &.native {
          left: calc(70% - 250px);
        }
      }
    }

    .tablet {
      width: 250px;
      height: 250px;
      right: 50px;
      bottom: -26px;

      .magnifier {
        right: 30px;
        top: 25px;
      }

      .example {
        top: -50px;

        &.native {
          top: -70px;
          right: -50px;
        }
      }
    }
  }
}
</style>
