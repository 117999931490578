<template>
  <b-modal
    id="upsert-callouts-modal"
    :modal-class="'default-modal modal-size-lg'"
    :hide-footer="true"
    @shown="shown"
    @hidden="hidden"
  >
    <div class="text-center mb-4">
      <h2 class="highlighted-color1">
        <span>Selecionar frases de destaque</span>
      </h2>
    </div>
    <div class="text-center mt-3">
      <div class="d-flex flex-column flex-md-row">
        <div class="col-12 col-md-6 text-start">
          <h3 class="highlighted-color1 font-size-medium mb-4">
            <span>Frases de destaque</span>
          </h3>
          <form @submit.prevent="" class="default-form">
            <label class="form-label fw-bold mb-2">
              Frase de destaque
            </label>
            <!-- CALLOUT -->
            <div class="form-group mb-2" v-for="(callout, index) in localCallouts" :key="index">
              <div class="d-flex justify-content-between">
                <!-- TEXT -->
                <div class="col-11 pe-2">
                  <input type="text" class="form-control" placeholder="Texto da frase de destaque" v-model.trim="localCallouts[index].text" />
                  <div class="d-flex justify-content-end">
                    <p class="font-size-xSmall text-end mt-1 mb-0"
                      :class="{
                        'text-red': localCallouts[index].text.length > 25
                      }"
                    >{{ `${ localCallouts[index].text.length }/25` }}</p>
                  </div>
                  <div
                    class="mb-2 alert-message-card text-color2 font-size-xSmall px-3 py-2"
                    v-if="checkIfTextIsRepeated(localCallouts[index].text, index)"
                  >
                    <p
                      class="mb-0"
                    >
                      Não são permitidas frases repetidas.
                    </p>
                  </div>
                </div> <!-- END TEXT -->
                <div class="col-1">
                  <Button
                    class="btn-danger btn-xs font-size-small btn-circle p-0 mt-2"
                    @click.prevent="destroyCallout(index)"
                    v-b-tooltip.hover title="Remover frase de destaque"
                  >
                    <i class="fa fa-trash"></i>
                  </Button>
                </div>
              </div>
            </div> <!-- END CALLOUT -->
          </form>
          <div class="mt-4 text-center">
            <Button
              class="btn btn-primary btn-xs"
              @click="addCallout"
            >
              Adicionar frase
            </Button>
          </div>
        </div>
        <div class="col-12 col-md-6 text-start ps-5">
          <h3 class="highlighted-color1 font-size-medium mb-4">
            <span>Prévia</span>
          </h3>
          <SearchAdPreview
            :ad="parsedAd"
            :highlightedAreas="['callouts']"
            :isDisclaimerVisible="true"
            :isShuffleEnabled="false"
          />
        </div>
      </div>

      <div class="mt-3">
        <Button
          type="button"
          class="btn btn-primary"
          @click="submit"
          :disabled="!isFormComplete"
        >
          Selecionar
        </Button>
      </div>
    </div>
  </b-modal>
</template>

<script>

import Button from '@/components/common/Button/Button'
import SearchAdPreview from '@/components/campaigns/Search/SearchAdPreview'

export default {
  name: 'UpsertCalloutsModal',
  props: ['ad', 'callouts'],
  components: {
    Button,
    SearchAdPreview
  },
  data () {
    return {
      content: null,
      content_type: 'error',
      localCallouts: []
    }
  },
  computed: {
    /**
     * Check if form is filled completely
     */
    isFormComplete () {
      let flag = true

      // Parse each item and check for title and url
      this.localCallouts.forEach(item => {
        if (
          !item.text ||
          item.text.length > 25
        ) {
          flag = false
        }
      })

      return flag
    },
    /**
     * Parsed ad
     */
    parsedAd () {
      const ad = JSON.parse(JSON.stringify(this.ad))

      ad.callouts = this.localCallouts

      return ad
    }
  },
  methods: {
    /**
     * Add callout to local callouts
     */
    addCallout () {
      this.localCallouts.push({
        text: ''
      })
    },
    /**
     * Check if text or URL exists previously in array
     */
    checkIfTextIsRepeated (text, index) {
      if (!text) return false
      const slice = this.localCallouts.slice(0, index)
      const indexText = slice.findIndex(item => {
        return item.text === text
      })

      return (indexText >= 0 && text)
    },
    /**
     * Destroy callout
     */
    destroyCallout (index) {
      this.localCallouts.splice(index, 1)
    },
    /**
     * Hidden
     */
    hidden () {
      this.localCallouts = []
    },
    /**
     * Shown
     */
    shown () {
      this.localCallouts = [...this.callouts]
    },
    /**
     * Submit
     */
    submit () {
      const callouts = []
      this.localCallouts.forEach(item => {
        const index = callouts.findIndex(i => {
          return i.text === item.text
        })

        if (index < 0) callouts.push(item)
      })

      this.$emit('callouts-upserted', callouts)
      this.$bvModal.hide('upsert-callouts-modal')
    }
  }
}
</script>

<style lang="scss" scoped>
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
</style>
