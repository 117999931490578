<template>
  <b-modal
    id="select-keywords-modal"
    :modal-class="'default-modal'"
    :hide-footer="true"
    @shown="shown"
    @hidden="hidden"
  >
    <div class="text-center">
      <h2 class="highlighted-color1">
        <span>Selecionar palavras-chave</span>
      </h2>
    </div>
    <AlertWraper
      :content="content"
      :type="content_type"
    />
    <div class="text-center mt-3">
      <!-- INIT STEP -->
      <template v-if="step === 'init'">
        <p class="text-center">Você pode inserir as palavras-chave manualmente ou utilizar a nossa ferramenta de sugestões automáticas para ter uma lista inicial de ideias!</p>
        <div class="mt-4 d-flex align-items-center justify-content-center">
          <Button
            type="button"
            class="btn btn-primary btn-sm me-3"
            @click="changeStep('keywords')"
          >
            Inserir manualmente
          </Button>
          <Button
            type="button"
            class="btn btn-primary btn-sm"
            @click="changeStep('planner')"
          >
            Ferramenta de sugestões
          </Button>
        </div>
      </template> <!-- END INIT STEP -->

      <!-- PLANNER STEP -->
      <template v-else-if="step === 'planner'">
        <form @submit.prevent="" class="default-form">
          <!-- WEBSITE -->
          <div class="form-group mb-3 text-start">
            <label class="form-label fw-bold">Site destino da campanha</label>
            <input type="text" class="form-control" placeholder="https://exemplo.com.br" v-model.trim="website" />
          </div> <!-- END WEBSITE -->

          <div class="mt-3">
            <Button
              type="button"
              class="btn btn-primary"
              @click="getKeywordsByPlanner"
              :loading="plannerLoader"
            >
              Gerar sugestões
            </Button>
          </div>
        </form>
      </template> <!-- END PLANNER STEP -->

      <!-- KEYWORDS STEP -->
      <template v-else-if="step === 'keywords'">
        <form @submit.prevent="submit" class="text-left default-form">
          <p class="text-start">Recomendamos a seleção de 20 a 30 palavras-chave para cada grupo de anúncios.</p>
          <div class="mb-2">
            <b-form-tags
              v-model="localKeywords"
              separator=",;"
              placeholder="Digite..."
              remove-on-delete
            ></b-form-tags>
          </div>
          <div class="text-start">
            <p>Palavras-chave selecionadas: {{ localKeywords.length }}</p>
          </div>
          <div class="mt-3">
            <Button
              type="button"
              class="btn btn-primary"
              @click="submit"
              :loading="loader"
            >
              Selecionar
            </Button>
          </div>
        </form>
      </template> <!-- END KEYWORDS STEP -->
    </div>
  </b-modal>
</template>

<script>

import SearchCampaignsService from '@/modules/campaigns/services/search-campaigns-service'
import { parseErrorResponseToArray } from '@/utils'

import AlertWraper from '@/components/common/Alert/AlertWraper'
import Button from '@/components/common/Button/Button'

export default {
  name: 'SelectKeywordsModal',
  props: ['keywords'],
  components: {
    AlertWraper,
    Button
  },
  data () {
    return {
      content: null,
      content_type: 'error',
      loader: false,
      localKeywords: [],
      plannerLoader: false,
      step: '',
      website: ''
    }
  },
  methods: {
    /**
     * Change step
     */
    changeStep (step) {
      this.step = step
    },
    /**
     * Hidden
     */
    hidden () {
      this.localKeywords = []
      this.step = 'init'
    },
    /**
     * Shown
     */
    shown () {
      this.localKeywords = [...this.keywords]
      this.step = this.localKeywords.length > 0 ? 'keywords' : 'init'
    },
    /**
     * Submit
     */
    submit () {
      this.$emit('keywords-selected', this.localKeywords)
      this.$bvModal.hide('select-keywords-modal')
    },
    /**
     * Get keywords by planner
     */
    async getKeywordsByPlanner () {
      this.content = null
      this.plannerLoader = true
      try {
        let website = this.website
        website = `https://${website.replace('https://', '').replace('http://', '')}`
        const keywords = await SearchCampaignsService.getKeywordsByPlanner({
          website: website
        })
        this.localKeywords = keywords.map(item => {
          return item.text
        })
        this.step = 'keywords'
      } catch (error) {
        this.content = parseErrorResponseToArray(error)
      }
      this.plannerLoader = false
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .b-form-tag-remove {
    padding: 0;
    background-color: transparent;
    border: 0;
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
  }
  .badge-secondary {
    color: #fff;
    background-color: $color9;
  }

  .b-form-tags-button {
    display: none;
  }
}
.b-form-tags-list {
  margin-top: -.25rem;
}
.b-form-tags-list .b-form-tag, .b-form-tags-list .b-form-tags-field {
  margin-top: .25rem;
}
.b-form-tag {
  font-size: 75%;
  font-weight: 400;
  line-height: 1.5;
  margin-right: .25rem;
}
.mw-100 {
  max-width: 100% !important;
}
.b-form-tag > button.b-form-tag-remove {
  color: inherit;
  font-size: 125%;
  line-height: 1;
  float: none;
  margin-left: .25rem;
}
</style>
