<template>
  <b-modal
    id="destroy-pixel-rule-modal"
    :modal-class="'default-modal'"
    :hide-footer="true"
  >
    <div class="text-center">
      <h2 class="highlighted-color1">
        <span>Apagar regra de pixel</span>
      </h2>
    </div>
    <div class="text-center mt-3">
      <div class="mb-4">
        <i class="fa fa-warning font-size-large"></i>
      </div>
      <p>Você tem certeza que deseja remover a regra de pixel <strong>{{ rule ? rule.name : '' }}</strong> da campanha?</p>
      <Button
        type="button"
        class="btn btn-primary mt-4"
        @click="click"
      >
        Remover
      </Button>
    </div>
  </b-modal>
</template>

<script>

import Button from '@/components/common/Button/Button'

export default {
  name: 'DestroyPixelRuleModal',
  props: ['rule'],
  components: {
    Button
  },
  methods: {
    /**
     * Submit
     */
    async click () {
      this.$bvModal.hide('destroy-pixel-rule-modal')
      this.$emit('pixel-rule-destroyed', this.rule)
    }
  }
}
</script>
