<template>
  <div>
    <div v-if="loader && !campaign && isEditPage" class="text-center">
      <Loader
        :size="'big'"
      />
    </div>
    <div v-if="isCreatePage">
      <CreateCampaign />
    </div>
    <div class="mt-3" v-if="!isEditPage || (isEditPage && campaign)">
      <ProgramaticCampaignEdit
        :baseCampaign="campaign"
        v-if="isProgramaticCampaign"
      />
      <NativeCampaignEdit
        :baseCampaign="campaign"
        v-if="isNativeCampaign"
      />
      <SearchCampaignEdit
        :baseCampaign="campaign"
        v-if="isGoogleAdsCampaign"
      />
    </div>
  </div>
</template>

<script>

import CampaignsService from '@/modules/campaigns/services/campaigns-service'
import variables from '@/mixins/variables'

import CreateCampaign from '@/components/campaigns/CreateCampaign'
import SearchCampaignEdit from '@/modules/campaigns/views/search/SearchCampaignEdit'
import NativeCampaignEdit from '@/modules/campaigns/views/native/NativeCampaignEdit'
import ProgramaticCampaignEdit from '@/modules/campaigns/views/programatic/ProgramaticCampaignEdit'

import Loader from '@/components/common/Loader/Loader'

import { mapState } from 'vuex'

export default {
  mixins: [variables],
  components: {
    Loader,
    CreateCampaign,
    SearchCampaignEdit,
    NativeCampaignEdit,
    ProgramaticCampaignEdit
  },
  data () {
    return {
      campaign: null,
      loader: false
    }
  },
  metaInfo () {
    return {
      title: this.pageTitle
    }
  },
  created () {
    if (this.isEditPage) {
      this.getCampaign()
    }
  },
  computed: {
    ...mapState('users', {
      user: state => state.user
    }),
    /**
     * Check if is edit page
     */
    isEditPage () {
      return this.$route.name === 'campaigns.edit'
    },
    /**
     * Check if is create page
     */
    isCreatePage () {
      return this.$route.name === 'campaigns.create'
    },
    /**
     * Check if is google ads campaign
     */
    isGoogleAdsCampaign () {
      // If is creation page, get name of route
      if (!this.isEditPage) return this.routeName === 'campaigns.search.create'

      // Check if has campaign and is of correct type
      return this.campaign && this.campaign.type === 'SEARCH'
    },
    /**
     * Check if is native campaign
     */
    isNativeCampaign () {
      // If is creation page, get name of route
      if (!this.isEditPage) return this.routeName === 'campaigns.native.create'

      // Check if has campaign and is of correct type
      return this.campaign && this.campaign.type === 'NATIVE'
    },
    /**
     * Check if is programatic campaign
     */
    isProgramaticCampaign () {
      // If is creation page, get name of route
      if (!this.isEditPage) return this.routeName === 'campaigns.programatic.create'

      // Check if has campaign and is of correct type
      return this.campaign && this.campaign.type === 'PROGRAMATIC'
    },
    /**
     * Get page title
     */
    pageTitle () {
      return this.$route.name === 'campaigns.programatic.create' || this.$route.name === 'campaigns.native.create' || this.$route.name === 'campaigns.create' ? 'Criar campanha' : 'Editar campanha'
    },
    /**
     * Router name
     */
    routeName () {
      return this.$route.name
    }
  },
  methods: {
    /**
     * Get advertiser
     */
    async getCampaign () {
      this.loader = true
      try {
        const campaign = await CampaignsService.getCampaign(this.$route.params.id)
        if (campaign.status === 'FINISHED') return this.$router.push({ name: 'campaigns.show', params: { id: campaign.id } })
        this.campaign = campaign
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
        this.$router.push('/')
      }
      this.loader = false
    }
  }
}
</script>
